<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            系統說明
          </h2>
          <b-card-text class="mb-2">
            若還有任何疑問可以聯絡客服
          </b-card-text>

          <!-- form -->
          <!-- <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="query"
                placeholder="Search faq..."
                @keyup.enter="handleSubmit"
              />
            </b-input-group>
          </b-form> -->
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >

        <!-- payment tab -->
        <b-tab
          v-for="(data, index) in faqTabs"
          :key="data"
          :active="!index"
        >

          <!-- title -->
          <template #title>
            <feather-icon
              v-if="faqTitle[data]"
              :icon="faqTitle[data].icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ $t(data) }}</span>
          </template>

          <faq-question-answer
            :options="dataByType(data)"
            :title="faqTitle[data]"
          />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>還有其他問題嗎？</h2>
          <b-card-text class="mb-3">
            若您無法尋找到您的問題請聯絡我們. 我們在收到通知後會回覆您！
          </b-card-text>
        </b-col>
        <b-col>
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-link href="https://t.me/joinchat/uck8lzW4oCdiNmM1">
              <b-img
                center
                src="@/assets/images/icons/telegram.png"
                width="50"
              />
            </b-link>
            <div><span class="text-body">Telegram</span></div>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardText, /* BForm, BInputGroup, BFormInput, BInputGroupPrepend,  */BTabs, BTab, BImg, BRow, BCol, BLink,
} from 'bootstrap-vue'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    BCard,
    BLink,
    BRow,
    BCol,
    BCardBody,
    BCardText,
    /*  BForm,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend, */
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
  },
  data() {
    return {
      searchQuery: '',
      query: '',
      faqTitle: {
        dashboard: {
          key: 'dashboard',
          icon: 'HomeIcon',
          title: 'dashboard',
          subtitle: '今日以前需要付款及需要支付罰款的顧客名單',
        },
        customers: {
          key: 'customers',
          icon: 'UsersIcon',
          title: 'customers',
          subtitle: '顧客清單',
        },
        transactions: {
          key: 'transactions',
          icon: 'FileTextIcon',
          title: 'transactions',
          subtitle: '交易記錄',
        },
        reports: {
          key: 'reports',
          icon: 'ActivityIcon',
          title: 'reports',
          subtitle: '報表',
        },
        settings: {
          key: 'settings',
          icon: 'SettingsIcon',
          title: 'settings',
          subtitle: '設定',
        },
      },
      qaData: [
        {
          key: 'dashboard',
          question: '支付利息如何計算？',
          ans: '付款金額 > 應付, 多於金額會成為零佣金。<br>當付款金額 > 應付 + 貸款，則會結清。',
        },
        {
          key: 'payment',
          question: 'Can I store the item on an intranet so everyone has access?',
          ans: 'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
        },
        {
          key: 'payment',
          question: 'What does non-exclusive mean?',
          ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
        },
        {
          key: 'payment',
          question: 'Is the Regular License the same thing as an editorial license?',
          ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
        },
        {
          key: 'payment',
          question: 'Which license do I need for an end product that is only accessible to paying users?',
          ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
        {
          key: 'payment',
          question: 'Which license do I need to use an item in a commercial?',
          ans: 'At tempor commodo ullamcorper a lacus vestibulum. Ultrices neque ornare aenean euismod. Dui vivamus arcu felis bibendum. Turpis in eu mi bibendum neque egestas congue. Nullam ac tortor vitae purus faucibus ornare suspendisse sed.',
        },
        {
          key: 'payment',
          question: 'Can I re-distribute an item? What about under an Extended License?',
          ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod lacinia at quis risus sed vulputate odio ut enim. Dictum at tempor commodo ullamcorper a lacus vestibulum.',
        },
        {
          key: 'delivery',
          question: 'Where has my order reached?',
          ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
        },
        {
          key: 'delivery',
          question: 'The shipment status shows that it has been returned/cancelled. What does it mean and who do I contact?',
          ans: 'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
        },
        {
          key: 'delivery',
          question: 'What if my shipment is marked as lost?',
          ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
        },
        {
          key: 'delivery',
          question: 'My shipment status shows that it’s out for delivery. By when will I receive it?',
          ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
        },
        {
          key: 'delivery',
          question: 'What do I need to do to get the shipment delivered within a specific timeframe?',
          ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
        {
          key: 'cancellation',
          question: 'Can my security guard or neighbour receive my shipment if I am not available?',
          ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
        },
        {
          key: 'cancellation',
          question: 'How can I get the contact number of my delivery agent?',
          ans: 'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
        },
        {
          key: 'cancellation',
          question: 'How can I cancel my shipment?',
          ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
        },
        {
          key: 'cancellation',
          question: 'I have received a defective/damaged product. What do I do?',
          ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
        },
        {
          key: 'cancellation',
          question: 'How do I change my delivery address?',
          ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
        {
          key: 'cancellation',
          question: 'What documents do I need to carry for self-collection of my shipment?',
          ans: 'At tempor commodo ullamcorper a lacus vestibulum. Ultrices neque ornare aenean euismod. Dui vivamus arcu felis bibendum. Turpis in eu mi bibendum neque egestas congue. Nullam ac tortor vitae purus faucibus ornare suspendisse sed. Commodo viverra maecenas accumsan lacus vel facilisis volutpat est velit. Tortor consequat id porta nibh. Id aliquet lectus proin nibh nisl condimentum id venenatis a. Faucibus nisl tincidunt eget nullam non nisi. Enim nunc faucibus a pellentesque. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Nec nam aliquam sem et tortor consequat id. Fringilla est ullamcorper eget nulla facilisi. Morbi tristique senectus et netus et.',
        },
        {
          key: 'cancellation',
          question: 'What are the timings for self-collecting shipments from the Delhivery Branch?',
          ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod lacinia at quis risus sed vulputate odio ut enim. Dictum at tempor commodo ullamcorper a lacus vestibulum.',
        },
      ],
    }
  },
  computed: {
    faqTabs() {
      return this.filterData.map(item => item.key).filter((value, index, self) => self.indexOf(value) === index)
    },
    filterData() {
      if (!this.seachQuery || this.seachQuery === '') return this.qaData
      const seachQueryIcon = this.seachQuery.toLowerCase()
      return this.qaData.filter(el => el.ans.toLowerCase().includes(seachQueryIcon))
    },
  },
  methods: {
    dataByType(type) {
      return this.filterData.filter(el => el.key === type)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
